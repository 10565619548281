import { guid } from './util';

export const isServer = typeof window === 'undefined';

export const isWeixinBrowser = isServer
  ? false
  : /micromessenger/.test(navigator.userAgent.toLowerCase());

export const isAndroid = isServer
  ? false
  : navigator.userAgent.indexOf('Android') > -1 ||
    navigator.userAgent.indexOf('Adr') > -1;

// export const isiOS = isServer
//   ? false
//   : !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

export const isiOS = isServer
  ? false
  : (function() {
      const iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ];

      if (!!navigator.platform) {
        while (iDevices.length) {
          if (navigator.platform === iDevices.pop()) {
            return true;
          }
        }
      }
      return false;
    })();

export const title = (title, websiteName = '神马网') => {
  if (isServer) return;
  if (title) {
    window.document.title = title + ' / ' + websiteName;
    // iPhone，iPod，iPad下无法更新标题
    if (/ip(hone|od|ad)/i.test(window.navigator.userAgent)) {
      let iframe = document.createElement('iframe');
      let body = document.querySelector('body');
      iframe.style.display = 'none';
      iframe.src = '/blank.html';
      iframe.onload = () => {
        setTimeout(() => {
          iframe.remove();
        }, 10);
      };
      body.appendChild(iframe);
    }
  }
};

export const setLocalStorage = (key, value) => {
  if (isServer) return;
  if (key && value) {
    try {
      const serialized = JSON.stringify(value);
      localStorage.setItem(key, serialized);
      return true;
    } catch (e) {
      alert('请确保你的浏览器已启用cookie然后重试');
    }
  }
  return false;
};

export const getFromLocal = key => {
  if (isServer) return;
  if (key) {
    try {
      const value = localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      } else {
        return null;
      }
    } catch (e) {
      alert('请确保你的浏览器已启用cookie然后重试');
    }
  }
};

export const os = (function() {
  if (isServer) return null;
  const ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc
  };
})();

export const drawGroupInventIamge = (
  width,
  height,
  groupName,
  groupMemberCount,
  groupImage,
  groupQRCodeUrl,
  groupCover
) => {
  //const canvas = document.createElement('canvas');

  const QRCode = require('qrcode');

  return new Promise((resolve, reject) => {
    const img = new Image();
    // img.setAttribute('crossOrigin', 'anonymous');
    const groupImg = new Image();
    groupImg.setAttribute('crossOrigin', 'anonymous');
    const coverImage = new Image();
    coverImage.setAttribute('crossOrigin', 'anonymous');
    //img.onload = () => {
    QRCode.toDataURL(groupQRCodeUrl).then(url => {
      img.src = url;
      groupImg.src = groupImage;
      groupImg.onload = () => {
        coverImage.src = groupCover;
        coverImage.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');

          const ratio = getPixelRatio(ctx);

          canvas.style.width = canvas.width + 'px';
          canvas.style.height = canvas.height + 'px';

          canvas.width = canvas.width * ratio;
          canvas.height = canvas.height * ratio;

          ctx.scale(ratio, ratio);

          ctx.beginPath();
          ctx.rect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = 'white';
          ctx.fill();

          ctx.drawImage(coverImage, 0, 0, width, (width / 75) * 23);
          ctx.fillStyle = 'rgba(0,0,0,0.65)';
          ctx.textAlign = 'center';
          ctx.font = '16px Verdana';
          ctx.fillText(groupName, width / 2, (width / 75) * 23 + 30);
          ctx.font = '14px Verdana';
          ctx.fillText(
            `${require('numeral')(groupMemberCount).format('0,0')}人`,
            width / 2,
            (width / 75) * 23 + 60
          );
          ctx.drawImage(groupImg, 50, 170, 200, (200 / 678) * 126);
          ctx.drawImage(img, (width - 90) / 2, 220, 90, 90);
          ctx.font = '14px Verdana';
          ctx.fillStyle = 'rgba(0,0,0,0.45)';
          ctx.fillText('长按识别加入小组', width / 2, 330);
          resolve(canvas.toDataURL('image/png'));
        };
        groupImg.onerror = () => {
          reject(new Error('download fail'));
        };
      };
    });
    //};
  });
};

const getPixelRatio = context => {
  const backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1;
  return (window.devicePixelRatio || 1) / backingStore;
};

export const getEnv = () => {
  if (isServer) return 'server';
  if (
    (window.navigator.userAgent.match(/micromessenger/i) &&
      window.navigator.userAgent.match(/miniprogram/i)) ||
    window.__wxjs_environment === 'miniprogram'
  ) {
    return 'miniProgram';
  }
  if (isWeixinBrowser) {
    return 'wx';
  }
  return '';
};

export const getDeviceId = () => {
  try {
    if (!getFromLocal('deviceId')) {
      const uuid = guid();
      setLocalStorage('deviceId', uuid);
      return uuid;
    } else {
      return getFromLocal('deviceId');
    }
  } catch (e) {
    return null;
  }
};

export const getPageWidth = () => {
  if (isServer) {
    return 0;
  }
  const width = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  return width <= 414 ? width : 414;
};

export const getPageHeight = () => {
  if (isServer) {
    return 0;
  }
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
};
